import React, { FC, useEffect } from 'react'
import {
  ContentStepType,
  StepDetailDocument,
  StepProgressionStatus,
  StepType,
  useCompleteStepMutation,
  useStartStepMutation,
  ViewAs,
} from '../../../graphql'
import { BlockEditor } from '../../../boxcast/components/TipTap/BlockEditor'
import { Button, Form, Text } from '../../../components/ui'
import StepQuestions from '../components/StepQuestions'
import useStepDetail from '../../../hooks/useStepDetail'
import EditableText from '../../../components/shared/EditableText'
import { Formik } from 'formik'
import * as Yup from 'yup'
import useAuth from '../../../hooks/useAuth'
import { useNavigate } from 'react-router'
import useToast from '../../../hooks/useToast'
import StepIcon from '../../../components/shared/StepIcon'

const StepDetail: FC = () => {
  const { step, updateStep } = useStepDetail()
  const { user } = useAuth()

  const navigate = useNavigate()
  const toast = useToast()

  const [startStep] = useStartStepMutation()
  const [completeStep, { loading: completing }] = useCompleteStepMutation({
    refetchQueries: [StepDetailDocument],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data.completeStep.__typename === 'CompleteStepSuccess') {
        toast.push('Success', data.completeStep.message, 'success')
        navigate('/my-journey/:id')
      }
    },
  })

  useEffect(() => {
    if (user?.viewingAs === ViewAs.Apprentice) {
      if (step?.id) {
        startStep({ variables: { where: { id: step.id } } })
      }
    }
  }, [user?.viewingAs, step?.id, startStep])

  const questions = step?.questions || []

  const initialValues = Object.fromEntries(questions.map((question) => [question.id, '']))

  const schema = Object.fromEntries(
    questions.map((question) => {
      if (question.required) {
        return [question.id, Yup.string().required('Required')]
      } else {
        return [question.id, Yup.string()]
      }
    }),
  )

  const validationSchema = Yup.object().shape(schema)

  return (
    <div className={'flex w-full self-center flex-col flex-1 pt-[15px] gap-[32px] max-w-[1130px] xl:max-w-[1120px]'}>
      <div className={'flex gap-[8px]'}>
        <StepIcon
          color={step?.phase?.color}
          type={step?.type || StepType.Content}
          contentType={step?.contentType || ContentStepType.Text}
          size={24}
        />
        <div className={'flex flex-col gap-[5px] w-full'}>
          {step?.draft ? (
            <>
              <EditableText
                className={'h2'}
                value={step?.name}
                placeholder={'Step title'}
                onChange={(event) => updateStep({ name: event.target.value })}
              />
              <EditableText
                value={step?.description}
                placeholder={'Add a description'}
                onChange={(event) => updateStep({ description: event.target.value })}
              />
            </>
          ) : (
            <>
              <Text variant={'h2'}>{step?.name}</Text>
              <Text>{step?.description}</Text>
            </>
          )}
        </div>
      </div>
      <div className={'flex flex-col'}>
        <BlockEditor
          key={step?.id}
          editable={!!step?.draft}
          content={step?.content}
          onChange={(content) => {
            updateStep({ content })
          }}
        />
      </div>
      {user?.viewingAs === ViewAs.Apprentice ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (step && user?.viewingAs === ViewAs.Apprentice) {
              const answers = Object.entries(values).map(([questionId, text]) => ({
                question: {
                  id: questionId,
                },
                text,
              }))
              await completeStep({
                variables: {
                  where: {
                    id: step.id,
                  },
                  data: {
                    answers,
                  },
                },
              })
            }
          }}>
          {({ isValid }) => (
            <Form>
              <div className={'flex flex-col gap-[30px]'}>
                <div>
                  <StepQuestions />
                </div>
                {step?.user.status !== StepProgressionStatus.Complete ? (
                  <div className={'flex justify-end'}>
                    <Button
                      loading={completing}
                      disabled={!isValid}
                      size={'lg'}
                      variant={'solid'}
                      label={'Mark Complete'}
                    />
                  </div>
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={'flex flex-col gap-[30px]'}>
          <div>
            <StepQuestions />
          </div>
        </div>
      )}
    </div>
  )
}

export default StepDetail
