import React, { ChangeEvent, FC } from 'react'
import { Text, Form, Input, Indicator } from '../../../components/ui'
import Select from '../../../components/ui/Select'
import { Formik, Field } from 'formik'
import { BlockEditor } from '../../../boxcast/components/TipTap/BlockEditor'
import { useJourneysDropdownQuery } from '../../../graphql'
import usePrograms from '../../../hooks/usePrograms'
import AsyncSelect from 'react-select/async'
import useJourneyOptions from '../../../hooks/useJourneyOptions'
import usePhaseOptions from '../../../hooks/usePhaseOptions'
import useAssigneeOptions from '../../../hooks/useAssigneeOptions'
import useStepTypeOptions from '../../../hooks/useStepTypeOptions'
import useStepDetail from '../../../hooks/useStepDetail'

const StepProperties: FC = () => {
  const { selectedProgram } = usePrograms()

  const { step, updateStep } = useStepDetail()

  const [journeyProps] = useJourneyOptions()
  const [phaseProps] = usePhaseOptions()
  const [assigneeProps] = useAssigneeOptions({ emptyOption: true })
  const [typeProps] = useStepTypeOptions({ type: step?.type, contentType: step?.contentType })

  const { data: journeyData, loading } = useJourneysDropdownQuery({
    variables: {
      programId: selectedProgram?.id || '',
    },
  })

  let singleJourney: { id: string; name: string } | undefined
  if (journeyData?.journeys?.total === 1) {
    const j = journeyData?.journeys?.list[0]
    singleJourney = j && {
      id: j.id,
      name: j.name,
    }
  }

  return loading && !journeyData ? (
    <div className={'flex justify-center items-center h-[150px]'}>
      <Indicator isSpinning />
    </div>
  ) : (
    <div className={'flex flex-col gap-[20px]'}>
      <div>
        <Text className={'text-gray'}>Properties</Text>
      </div>
      <Formik
        initialValues={{
          journey: undefined,
          instructions: undefined,
        }}
        onSubmit={() => {
          console.log('Update properties')
        }}>
        {() => (
          <div className={'flex flex-col gap-[15px]'}>
            <Form.Item label={'Phase'}>
              <Select
                size={'sm'}
                key={`phase-dropdown-${selectedProgram?.id}`}
                cacheOptions
                {...phaseProps}
                defaultOptions
                isMulti={false}
                onChange={(value) => value && updateStep({ phase: value })}
                value={step?.phase}
                componentAs={AsyncSelect}
              />
            </Form.Item>
            {!singleJourney && (
              <Form.Item label={'Journey'}>
                <Select
                  {...journeyProps}
                  size={'sm'}
                  key={`journey-dropdown-${selectedProgram?.id}`}
                  cacheOptions
                  defaultOptions
                  isMulti={false}
                  onChange={(value) => value && updateStep({ journey: value })}
                  value={step?.journey}
                  componentAs={AsyncSelect}
                />
              </Form.Item>
            )}
            <Form.Item label={'Type'}>
              <Select
                size={'sm'}
                {...typeProps}
                isMulti={false}
                onChange={(value) =>
                  value && updateStep({ type: value.detail.type, contentType: value.detail.contentType })
                }
              />
            </Form.Item>
            <Form.Item label={'Assignee'}>
              <Select
                size={'sm'}
                key={`assignee-dropdown-${selectedProgram?.id}`}
                cacheOptions
                defaultOptions
                {...assigneeProps}
                isMulti={false}
                onChange={(value) => (value?.id ? updateStep({ assignees: [value] }) : updateStep({ assignees: [] }))}
                value={step?.assignees.length ? step?.assignees[0] : null}
                componentAs={AsyncSelect}
                isClearable
              />
            </Form.Item>
            <Form.Item label={'Instructions'}>
              <Field
                name={'instructions'}
                size={'sm'}
                textArea
                value={step?.instructions}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => updateStep({ instructions: event.target.value })}
                placeholder={'e.g. Read this article and be prepared to discuss at our next touchpoint.'}
                component={Input}
              />
            </Form.Item>
            <div className={'mt-[15px] flex flex-col gap-[20px]'}>
              <Text variant={'h2'}>Leader Notes</Text>
              <div className={'border-[1px] border-gray-300'} />
              <div className={'w-full'}>
                <BlockEditor
                  variant={'simple'}
                  content={step?.leaderInformation}
                  onChange={(content) => {
                    updateStep({ leaderInformation: content })
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  )
}

export default StepProperties
